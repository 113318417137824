<template>
  <div class="loading-payment-check">
    <!-- modal terms -->
    <div>
      <b-modal
        id="loading-payment-check"
        hide-header
        hide-footer
        no-close-on-backdrop
      >
        <div class="text-center" style="margin: 10px">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="d-block text-center">
          <div>{{ status }}</div>
          <div>{{ message }}</div>
        </div>
      </b-modal>

    </div>、
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";

export default {

  name: "external-payment-redidect",
  data() {
    return {
      status: '読み込み中です。',
      message: 'しばらく、そのままでお待ちください。',
      localStorageData: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        external_payment_token: localStorage.getItem(Constants.EXTERNAL_PAYMENT_TOKEN),
        user_info: JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO)),
        user_id: localStorage.getItem(Constants.USER_ID),
        user_email: localStorage.getItem(Constants.EMAIL_USER),
      }
    };
  },
  async mounted() {
    this.status = '決済情報を登録しています。';
    this.$bvModal.show('loading-payment-check');
    const data = {
      identifier: this.$route.params.identifier,
      referrer: document.referrer,
      localStorageData: this.localStorageData,
      //log_data
      title: this.$route.meta.title,
      url: window.location.origin + this.$route.fullPath,
    }
    await Api.userRequestServer
      .post(`/${Urls.EXTERNAL_PAYMENT_REDIRECT}`, data)
      .then( res => {
        if (res.data.success) {
          localStorage.setItem('external_payment_history_id', res.data.data.history.id);
          this.status = 'ログイン情報を確認しています。';
          this.$router.push({
            name: this.$route.params.shopId
              ? "external_payment_check"
              : "external_payment_check domain",
          });
        } else {
          this.status = '決済情報の登録に失敗しました。';
          this.message = 'システム管理者にお問い合わせしてください。';
        }
      })
      .catch( res => {
        console.log(res);
        this.status = 'システムエラーが発生しました。';
        this.message = 'システム管理者にお問い合わせしてください。';
      });
  },
};
</script>
<style lang="scss">
</style>
